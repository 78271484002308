import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a8a54d40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "MenuItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MenuItem = _resolveComponent("MenuItem", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userMenus, (menu) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!menu.children && menu.meta.prop==1 && menu.meta.show==0)
          ? (_openBlock(), _createBlock(_component_router_link, {
              to: "",
              onClick: ($event: any) => (_ctx.toPage(menu.page,menu.meta.prop,menu.meta.type)),
              key: menu.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, {
                  index: menu.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(menu.meta.icon),
                      style: {"font-size":"14px"}
                    }, null, 2),
                    _createElementVNode("span", null, _toDisplayString(menu.meta.title), 1)
                  ]),
                  _: 2
                }, 1032, ["index"])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        (!menu.children && (menu.meta.prop==0 || menu.meta.prop==2) && menu.meta.show==0)
          ? (_openBlock(), _createBlock(_component_router_link, {
              to: menu.path,
              key: menu.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, {
                  index: menu.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(menu.meta.icon),
                      style: {"font-size":"14px"}
                    }, null, 2),
                    _createElementVNode("span", null, _toDisplayString(menu.meta.title), 1)
                  ]),
                  _: 2
                }, 1032, ["index"])
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true),
        (menu.children && menu.meta.show==0)
          ? (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: menu.id,
              index: menu.id
            }, {
              title: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(menu.meta.icon),
                  style: {"font-size":"14px"}
                }, null, 2),
                _createElementVNode("span", null, _toDisplayString(menu.meta.title), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_MenuItem, {
                  userMenus: menu.children
                }, null, 8, ["userMenus"])
              ]),
              _: 2
            }, 1032, ["index"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}