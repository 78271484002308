import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c1cdd18"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "content-container",
  id: "content"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Portal = _resolveComponent("Portal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.outerLink)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          src: _ctx.outerLink,
          width: "100%",
          height: "100%",
          frameborder: "0",
          scrolling: "auto",
          id: "outerLink"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isRouterAlive && !_ctx.outerLink)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
          default: _withCtx(({Component}) => [
            _createVNode(_Transition, { name: _ctx.animationName }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_KeepAlive, null, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { ref: "compRef" }, null, 512))
                ], 1024))
              ]),
              _: 2
            }, 1032, ["name"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Portal, {
      ref: el=>_ctx.refMap.set('portal',el)
    }, null, 512)
  ]))
}